.pageTitle {
  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    opacity: 0.7;
    filter: alpha(opacity=70);
  }

  .pageTitleHeading h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 35px;
    line-height: 40px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    padding: 113px 0 0;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    margin-bottom: 9px;
  }
}

.breadcrumbs {
  text-align: center;
  padding-bottom: 96px;
  ul li,
  ul li a {
    font-size: 14px;
    line-height: 22px;
    font-family: "Poppins", sans-serif;
    color: #fff;
  }

  ul {
    li {
      display: inline-block;
      padding: 0px 15px 20px 0;
      color: #fff;
      opacity: 0.5;

      a:hover {
        color: #58a1ce;
      }

      a {
        position: relative;
      }
    }

    .home {
      padding: 0px 5px 20px 0;
    }
  }
}
