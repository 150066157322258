@import "../../styles/shorcodes.module.scss";

.header {
  background-color: $white;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;

  .headerWrap {
    max-width: 1920px;
    margin: 0 auto;
    position: relative;

    .logo {
      width: 123px;
      height: 88px;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      float: left;
      margin: 5px 0px 5px 0;
      padding: 0px 0px 0px 30px;
    }

    .navWrap {
      float: right;
    }
  }
}

.showSearch {
  float: right;

  a {
    line-height: 95px;
    position: relative;

    i {
      font-size: 14px;
      color: $white;
      background-color: $silverbfb;
      padding: 10px;
      border-radius: 50%;
      text-align: center;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: $danube;
      }
    }
  }
}

.top-search {
  position: absolute;
  width: 300px;
  right: 0;
  top: 100px;
  opacity: 0;
  z-index: -99;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.widget.widget_search .search-form {
  position: relative;

  input[type="submit"] {
    color: $white;
    height: 45px;
    line-height: 45px;
    padding: 0 40px;
    cursor: pointer;
    white-space: nowrap;
    border: none;
    display: inline-block;
    font-weight: bold;
    font-size: 0;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
  }
}

.top-search.show {
  top: 100%;
  opacity: 1;
  z-index: 9999;
}

.widget.widget_search .search-form .search-submit {
  //   background-image: url(../images/icon/arrow.png);
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 11px;
  padding: 0;
  position: absolute;
  background-color: transparent;
  right: 0;
  top: 0;
  -webkit-opacity: 0.3;
  -khtml-opacity: 0.3;
  -moz-opacity: 0.3;
  opacity: 0.3;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=30);
  filter: alpha(opacity = 30);

  &:hover {
    background-color: transparent;
    -webkit-opacity: 1;
    -khtml-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
    filter: alpha(opacity = 100);
  }
}

/* Header Fix */

// .header.is-sticky {
//   width: 100%;
//   z-index: 1000;
//   -webkit-transition: all 0.3s ease-in-out;
//   -moz-transition: all 0.3s ease-in-out;
//   -ms-transition: all 0.3s ease-in-out;
//   -o-transition: all 0.3s ease-in-out;
//   transition: all 0.3s ease-in-out;

//   .header-wrap .logo {
//     margin-top: 23px;
//   }

//   #mainnav > ul > li > a,
//   .header-wrap .show-search a {
//     line-height: 80px;
//   }
// }

// .home-boxed .header.is-sticky {
//   width: 95.4%;
// }

// .header-top .header.is-sticky {
//   background-color: $white;

//   #mainnav > ul > li > a {
//     color: $quick-silver999;

//     &:hover {
//       color: #ec6a5c;
//     }
//   }
// }

.top .right-bar {
  float: right;

  .flat-information {
    margin-right: 21px;
  }
}

/* navigation */

.navWrap {
  position: relative;
}

#mainnav,
.mainnav {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
    }
  }

  > ul > li {
    display: inline-block;

    > a {
      position: relative;
      display: block;
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      text-transform: uppercase;
      color: $tundora424;
      text-decoration: none;
      line-height: 98px;
      padding: 0 55px 0 0;
      outline: none;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
  }

  .menu .has-sub:before {
    position: absolute;
    top: 38px;
    right: 33px;
    font-family: "FontAwesome";
    font-size: 14px;
    content: "\f107";
    color: $tundora424;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  ul {
    li:last-child a {
      padding: 0 0px 0 0;
    }

    &.submenu {
      position: absolute;
      left: 0;
      top: 150%;
      width: 345px;
      background-color: $eerie-black;
      z-index: 9999;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity = 0);
      opacity: 0;
      visibility: hidden;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }

    &.right-sub-menu {
      left: auto;
      right: 0;
    }

    li {
      &:hover > ul.submenu {
        top: 100%;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        filter: alpha(opacity = 100);
        opacity: 1;
        visibility: visible;
      }

      ul li {
        margin-left: 0;
      }
    }

    &.submenu {
      li {
        ul {
          position: absolute;
          left: 360px;
          top: 0 !important;
        }

        &:first-child {
          border-top: none;
        }
      }

      > li {
        border-top: 1px solid $mine-shaft333;

        &.sub-parent:after {
          content: "\f105";
          font-family: "FontAwesome";
          font-size: 14px;
          position: absolute;
          right: 25px;
          top: 11px;
          color: $quick-silver999;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -ms-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
        }
      }

      > li {
        > a {
          display: block;
          font-family: "Montserrat", sans-serif;
          letter-spacing: 1px;
          font-size: 14px;
          color: $quick-silver999;
          text-decoration: none;
          padding: 0 0 0 26px;
          line-height: 50px;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -ms-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;

          &:hover {
            background-color: $danube;
            color: $white;
          }
        }

        &.sub-parent:hover::after {
          right: 18px;
        }
      }
    }
  }

  .menu .has-sub:hover:before {
    color: $danube;
  }

  > ul > li > a {
    &:hover {
      color: $danube;
    }

    &.active {
      color: $danube !important;
    }
  }
}

/* submenu */

/* right sub-menu */

/* Mobile navigation
    ---------------------------------------- */

#mainnav-mobi {
  display: block;
  margin: 0 auto;
  width: 100%;
  position: absolute;
  background-color: $eerie-black;
  z-index: 1000;

  ul {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      position: relative;
      text-align: left;
      border-top: 1px solid $mine-shaft333;
      cursor: pointer;
    }

    > li > a {
      text-decoration: none;
      height: 50px;
      line-height: 50px;
      padding: 0 50px;
      color: $white;
    }

    &.sub-menu {
      top: 100%;
      left: 0;
      z-index: 2000;
      position: relative;
      background-color: $mine-shaft333;

      > li > a {
        display: block;
        text-decoration: none;
        padding: 0 60px;
        border-top-color: rgba(255, 255, 255, 0.1);
        -webkit-transition: all 0.2s ease-out;
        -moz-transition: all 0.2s ease-out;
        -o-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
      }

      > li > a:hover {
        color: $white;
      }
    }
  }

  > ul > li > ul > li {
    position: relative;
    border-top: 1px solid $mine-shaft333;

    > ul > li {
      position: relative;
      border-top: 1px solid $mine-shaft333;

      a {
        padding-left: 70px !important;
      }
    }
  }

  > ul > li > ul > li:first-child a {
    border-top: none;
  }

  > ul > li > ul > li.active > a {
    color: $white;
  }
}

.btnMenu {
  display: none;
  float: right;
  position: relative;
  background: transparent;
  cursor: pointer;
  margin: 40px 40px 40px 0;
  width: 26px;
  height: 16px;
  -webkit-transition: all ease 0.238s;
  -moz-transition: all ease 0.238s;
  transition: all ease 0.238s;

  &:before,
  &:after,
  span {
    background-color: $danube;
    -webkit-transition: all ease 0.238s;
    -moz-transition: all ease 0.238s;
    transition: all ease 0.238s;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    height: 2px;
    width: 100%;
    left: 0;
    top: 50%;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }

  span {
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    top: 50%;
    overflow: hidden;
    text-indent: 200%;
  }

  &:before {
    -webkit-transform: translate3d(0, -7px, 0);
    transform: translate3d(0, -7px, 0);
  }

  &:after {
    -webkit-transform: translate3d(0, 7px, 0);
    transform: translate3d(0, 7px, 0);
  }

  &.active {
    span {
      opacity: 0;
    }

    &:before {
      -webkit-transform: rotate3d(0, 0, 1, 45deg);
      transform: rotate3d(0, 0, 1, 45deg);
    }

    &:after {
      -webkit-transform: rotate3d(0, 0, 1, -45deg);
      transform: rotate3d(0, 0, 1, -45deg);
    }
  }
}

.btn-submenu {
  position: absolute;
  right: 20px;
  top: 0;
  font: 20px/50px "FontAwesome";
  text-align: center;
  cursor: pointer;
  width: 70px;
  height: 44px;

  &:before {
    content: "\f107";
    color: $white;
  }

  &.active:before {
    content: "\f106";
  }
}

/* parallax4 */

.page-title {
  position: relative;

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $black;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    opacity: 0.7;
    filter: alpha(opacity = 70);
  }

  .page-title-heading h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 35px;
    line-height: 40px;
    font-weight: 700;
    color: $white;
    text-align: center;
    padding: 113px 0 0;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    margin-bottom: 9px;
  }
}

.breadcrumbs {
  text-align: center;
  padding-bottom: 96px;

  ul li {
    font-size: 14px;
    line-height: 22px;
    font-family: "Poppins", sans-serif;
    color: $white;

    a {
      font-size: 14px;
      line-height: 22px;
      font-family: "Poppins", sans-serif;
      color: $white;

      &:hover {
        color: $danube;
      }

      position: relative;

      &:after {
        position: absolute;
        top: 0;
        right: -12px;
        font-family: "FontAwesome", sans-serif;
        content: "\f105";
        font-size: 14px;
      }
    }

    display: inline-block;
    padding: 0px 15px 20px 0;
    color: $white;
    opacity: 0.5;
  }
}
