@import "../../styles/shorcodes.module.scss";

.mainContent {
  .contentPost {
    .titlePost {
      a {
        font-family: "Montserrat", sans-serif;
        font-size: 18px;
        line-height: 18px;
        color: #333;
      }
    }
  }
}
