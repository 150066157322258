@import "../../styles/shorcodes.module.scss";

.mainContent {
  &.courseGrid {
    padding-top: 104px;

    .customContainer {
      position: relative;
    }

    .flatPostOrdering {
      position: absolute;
      top: 2px;
      right: 0;

      .sortViews {
        float: right;

        .chevron {
          position: absolute;
          top: 15px;
          right: 15px;
          font-size: 11px;
          line-height: 40px;
          color: #b1b1b1;
        }
      }
    }

    .postWrap {
      margin-bottom: 21px;
      .elements {
        margin: 0;
      }
    }

    .flatCourse {
      margin-bottom: 33px;
      width: 33.33333333333333%;
      padding: 0 15px;
      float: left;
    }
  }
}

.blogPosts {
  .blogTitle {
    h1 {
      font-family: "Montserrat", sans-serif;
      font-size: 35px;
      text-transform: uppercase;
      margin-bottom: 70px;
      color: #333;
      font-weight: 700;
      position: relative;

      &:before {
        position: absolute;
        left: 0;
        bottom: -20px;
        width: 35px;
        height: 1px;
        content: "";
        background-color: #333;
      }
    }
  }
}
