@import "~bootstrap/scss/bootstrap";
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import "./reset.scss";
@import "./repeatable-patterns.scss";
@import "./preload.scss";

#header {
  width: 100%;
  z-index: 101;

  //   &.downscrolled {
  //     position: fixed;
  //     top: 0;
  //     width: 100%;
  //     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  //     opacity: 0;
  //     filter: alpha(opacity = 0);
  //     top: -60px;
  //     max-width: 1920px;
  //     margin: 0 auto;
  //     z-index: 999999;
  //     -webkit-transition: all 0.5s ease-in-out;
  //     -moz-transition: all 0.5s ease-in-out;
  //     -ms-transition: all 0.5s ease-in-out;
  //     -o-transition: all 0.5s ease-in-out;
  //     transition: all 0.5s ease-in-out;
  //   }

  //   &.upscrolled {
  //     opacity: 1;
  //     top: 0;
  //     max-width: 1920px;
  //     margin: 0 auto;
  //     background: #fff;
  //     box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.04);
  //     z-index: 999999;
  //   }
}

.sticky {
  z-index: 2;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }

  #mainnav {
    display: block;
  }

  #mainnav-mobi {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .container {
    padding: 0 15px;
  }
}
