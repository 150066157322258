@import "../../styles/shorcodes.module.scss";


.flatContactForm{
    svg{
        position: absolute;
        top: 20px;
        left: 19px;
        color: #bcbcbc;
    }
}

.contact .commentForm .nameContainer, .flatContactForm .inputWrap.name {
    width: 32%;
    float: left;
    margin-right: 2%;
    &::before{
        content: " ";
    }
    &:before{
        content: " ";
    }
}

.contact .commentForm .emailContainer, .flatContactForm .inputWrap.email {
    float: left;
    width: 32%;
    &::before{
        content: " ";
    }
    &:before{
        content: " ";
    }
}

.contact .commentForm .subjectContainer, .flatContactForm .inputWrap.Subject {
    float: right;
    width: 32%;
    &::before{
        content: " ";
    }
    &:before{
        content: " ";
        position: relative;
    }
    
}

.flatContactForm .inputWrap, .flatContactForm .textareaWrap {
    position: relative;
    &::before{
        content: " ";
    }
    &:before{
        content: " ";
        position: relative;
    }
}

.commentRespond.style2 h1.commentTitle {
    margin-bottom: 60px;
    text-align: center;
}
