@import "./colors.scss";

/** 
*	Row
*	Button
*	Title section
*	Flat divider
*	Iconbox
*	Icon box left
*	Flat contact us
*	Flat list
*	Flat title box
*	Flat price table
*	Flat accordion
*	Flat tabs
*	Content box
*	Flat information box
*	Contact form
*	flat-counter
*	flat portfolio
*	Portfolio Detail
*	Related-portfolio
*	flat-testimonials
*	Progress Bar
*	flat-team
*	flat-socials
*/

/* Row
-------------------------------------------------------------- */

.flatRow {
  clear: both;
  display: block;
  position: relative;
  padding: 93px 0 92px;

  &.rowBg {
    background-color: #fcfcfc;
  }

  &.padTop134 {
    padding-top: 134px;
  }

  &.padTop100 {
    padding-top: 100px;
  }

  &.padBottom100 {
    padding-bottom: 100px;
  }

  &.padBottom30 {
    padding-bottom: 30px;
  }
}

.padTop96 {
  padding-top: 96px;
}

.fr {
  float: right;
}

.mgbt36 {
  margin-bottom: 36px;
}

.mgbt17 {
  margin-bottom: 17px;
}

.mgbt48 {
  margin-bottom: 48px;
}

.mgbt26 {
  margin-bottom: 26px;
}

/* Flat Grid
-------------------------------------------------------------- */

.flatGrid {
  margin: 0 -15px;
}

.flatColHalf,
.flatColThree,
.flatColFour,
.flatColSix {
  padding: 0 15px;
  float: left;
}

.flatOffset {
  margin-right: 8.333333333333333%;
}

.flatColHalf {
  width: 50%;
}

.flatColThree {
  width: 33.333333333333336%;
}

.flatColFour {
  width: 25%;
}

.flatColSix {
  width: 50%;
}

/* Margin 20px */

.flatGrid.margin20px {
  margin: 0 -20px;

  .flatColHalf,
  .flatColThree,
  .flatColFour {
    padding: 0 20px 40px 20px;
    float: left;
  }
}

.flatLh28 {
  line-height: 28px;
}

.readMore {
  color: #ffaa30;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  &:after {
    color: #ffaa30;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    content: "\f178";
    font-family: FontAwesome;
    padding-left: 10px;
  }

  &:hover {
    color: #333;

    &:after {
      color: #333;
      padding-left: 15px;
    }
  }
}

.wrapGrid {
  margin: 0 -10px;
  overflow: hidden;
}

.colHalf {
  width: 48.29059829059829%;
  float: left;
  background-color: #feb800;
  margin: 0 0.854700854700855%;

  /*margin-right: 1.70940170940171%;*/
}

.bgColorRed {
  background-color: #f96868;
}

.colLast {
  margin-right: 0;
}

/* On three */

.wrapFlatGrid {
  margin: 0 -15px;
  overflow: hidden;
}

.colThree {
  width: 30.76923076923077%;
  float: left;
  margin: 0 1.282051282051282%;
}

/* On Four */

.colFour {
  width: 22.43589743589744%;
  float: left;
  margin: 0 1.282051282051282%;
}

/* Bg-overlay */

/* Flat padding
-------------------------------------------------------------- */

.flatNoPdr {
  padding-right: 0;
}

.flatNoPdl {
  padding-left: 0;
}

.flatNoPd {
  padding: 0;
}

/* Button
-------------------------------------------------------------- */

/* Flat button
-------------------------------------------------------------- */

.flatButton {
  color: #ffffff;
  display: inline-block;
  font-weight: 400;
  line-height: 46px;
  padding: 0 17px;
  text-transform: uppercase;
  position: relative;
  border: 2px solid #fff;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 2px;

  &:hover {
    background: #ffaa30;
    border-color: #ffaa30;
    color: #fff;
  }

  &.orange {
    color: #ffaa30;
    border-color: #ffaa30;

    &:hover {
      color: #fff;
    }
  }

  &.bgOrange {
    background: #0089ff;
    border-color: #0089ff;

    &:hover {
      background-color: #222;
      border-color: #222;
      color: #fff;
    }
  }

  &.blue {
    background-color: #428bdc;
    border-color: #428bdc;
    color: #fff;

    &:hover {
      background: #ffaa30;
      border-color: #ffaa30;
    }
  }
}

/* Button background white */

/* Button background black */
/* Button background theme */
/* Title section
-------------------------------------------------------------- */

.flatTitleSection {
  position: relative;
  margin-bottom: 49px;
  padding-bottom: 15px;

  h1 {
    margin-bottom: 0px;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 35px;
    height: 1px;
    background: #333;
    left: 0;
  }
}

/* Flat contact form
-------------------------------------------------------------- */

.flatContactForm {
  .wrapTypeInput {
    overflow: hidden;
  }

  label.error {
    font-size: 11px;
    font-style: italic;
    color: #ffaa30;
    margin-top: -15px;
  }
}

.commentRespond.style2 input[type="text"] {
  margin-bottom: 30px;
}

.contact .commentForm .nameContainer {
  width: 32%;
  float: left;
  margin-right: 2%;
}

.flatContactForm {
  .inputWrap {
    &.name {
      width: 32%;
      float: left;
      margin-right: 2%;
    }

    &::before {
      content: "\f007";
      font-family: "FontAwesome";
      font-size: 14px;
      line-height: 23px;
      position: absolute;
      top: 13px;
      left: 15px;
      color: #bcbcbc;
    }
  }

  .textareaWrap:before {
    content: "\f007";
    font-family: "FontAwesome";
    font-size: 14px;
    line-height: 23px;
    position: absolute;
    top: 13px;
    left: 15px;
    color: #bcbcbc;
  }

  textarea {
    padding: 16px 21px;
    height: 200px;
    margin-bottom: 23px;
    max-width: 669px;
  }

  &.style2 {
    input {
      &[type="text"],
      &[type="email"] {
        padding-left: 45px;
        padding-top: 15px;
      }
    }

    #name {
      padding-left: 36px;
    }

    textarea {
      max-width: 100%;
      height: 220px;
      padding-left: 44px;
    }

    .inputWrap::before,
    .textareaWrap:before {
      top: 14px;
      left: 19px;
    }
  }

  .submitWrap button {
    padding-right: 24px;
    padding-left: 26px;
  }

  .textareaWrap:before {
    content: "\f075";
  }

  .inputWrap {
    &.Subject:before {
      content: "\f084";
    }

    &.email:before {
      content: "\f0e0";
    }
  }
}

.contact .commentForm .emailContainer,
.flatContactForm .input-wrap.email {
  float: left;
  width: 32%;
}

.contact .commentForm .subjectContainer {
  float: right;
  width: 32%;
}

.flatContactForm {
  .inputWrap {
    &.Subject {
      float: right;
      width: 32%;
    }

    position: relative;
  }

  .textareaWrap {
    position: relative;
  }
}

/* Iconbox
-------------------------------------------------------------- */

.contactContent {
  text-align: center;
  border: 2px solid #e5e5e5;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  &:hover {
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
    -ms-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
    -o-box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  .contactAddress {
    padding: 53px 40px 59px;
    position: relative;
  }
}

.contactAddress .style1 img {
  margin-top: -128px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 90px;
    height: 90px;
    background: yellow;
    border: 1px solid #000;
  }
}

.contactContent .details {
  h5 {
    font-family: "montserrat", sans-serif;
    font-size: 20px;
    color: #333333;
    line-height: 25px;
    margin-bottom: 17px;
  }

  p {
    margin-bottom: 1px;
    a {
      color: #000000;
    }
  }
}

/* Icon left */
/* Flat contact us
-------------------------------------------------------------- */

/* Flat list
-------------------------------------------------------------- */
/* Flat title box
-------------------------------------------------------------- */

.titlePd135 .boxTitle {
  padding-right: 135px;
}

/* Flat price table
-------------------------------------------------------------- */

/* Flat accordion
-------------------------------------------------------------- */

/* Flat tabs
-------------------------------------------------------------- */

.flatTabs {
  ul.menuTab {
    overflow: hidden;
    position: relative;
    margin-bottom: 50px;

    &:after {
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 2px;
      background-color: #eeeeee;
      content: "";
      z-index: 0;
    }

    li {
      display: inline-block;

      a {
        position: relative;
        z-index: 1;
        background-color: #fff;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
        border: 2px solid #eeeeee;
        list-style-type: none;
        border-radius: 50%;
        margin-right: 250px;
        display: block;

        &:before {
          width: 20px;
          height: 2px;
          background-color: #fff;
          content: "";
          top: 50%;
          left: -22px;
          position: absolute;
        }

        &:after {
          width: 20px;
          height: 2px;
          background-color: #fff;
          content: "";
          top: 50%;
          left: -22px;
          position: absolute;
          left: 100%;
          margin-left: 2px;
        }
      }

      &.active a,
      a:hover {
        border: 2px solid #ffaa30;
        color: #fff;
        background-color: #ffaa30;
        color: #fff;
      }

      &:last-child a {
        margin-right: 0;
      }

      a {
        font-family: "montserrat", sans-serif;
        font-weight: 700;
        color: #999999;
        font-size: 16px;
      }
    }
  }

  .flatTitle {
    margin-bottom: 34px;
  }

  .contentTab {
    .textTab {
      width: 100%;
      float: left;
      padding-right: 60px;
      padding-top: 5px;
    }

    .imagesTab {
      width: 40.17094017094017%;
      float: left;
    }

    p {
      margin-bottom: 20px;
    }
  }
}

/* Flat-list
-------------------------------------------------------------- */

ul.flatList {
  padding: 0 0 0 22px;
  margin: 0;

  li {
    padding: 6px 0;
    position: relative;

    // &:before {
    //   position: absolute;
    //   left: 0;
    //   top: 4px;
    //   font-family: "FontAwesome";
    //   content: "\f00c";
    //   font-size: 14px;
    //   color: #ffaa30;
    //   color: #333;
    // }
  }
}

/* Content box
-------------------------------------------------------------- */

/* Flat information box
-------------------------------------------------------------- */

.courseSingle .courseEntry .curriculumSection .title {
  padding-top: 40px;
}

/* Flat curriculumn
-------------------------------------------------------------- */

ul.curriculum li {
  &.section {
    margin-bottom: 30px;
    border: 1px solid #e5e5e5;
    padding: 38px 28px 14px;
    background: #fdfdfd;

    .sectionTitle {
      margin-bottom: 26px;
      word-spacing: 1px;
      letter-spacing: -0.2px;
    }
  }

  ul.sectionContent li.courseLesson {
    border-top: 1px solid #e5e5e5;
    line-height: 65px;
    counter-increment: step-counter;

    &:before {
      content: counter(step-counter) ".";
      margin-right: 5px;
      font-size: 13px;
      font-weight: bold;
      color: #333;
      font-family: poppins, sans-serif;
    }

    i {
      font-size: 13px;
      color: #697ea4;
      padding-right: 9px;
    }

    .index.index1 {
      padding-right: 60px;
    }

    .lessonTitle {
      font-size: 13px;
      padding-right: 12px;
      color: #000000;

      &:hover {
        color: #ffaa30;
      }
    }

    .lessonPreview {
      display: inline-block;
      color: #fff;
      font-size: 14px;
      line-height: 24px;
      border-radius: 2px;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      background: #428bdc;
      padding: 0 12px;
    }

    span.lessonPreview {
      background: #c3c3c3;
      margin-left: 4px;
      padding-right: 16px;
    }

    a.lessonPreview:hover {
      background: #ffaa30;
    }

    .meta {
      float: right;
    }

    .fr {
      padding-right: 12px;

      span,
      a {
        position: relative;
        padding-left: 17px;
        margin-left: 15px;
      }

      span:before,
      a:before {
        font-family: FontAwesome;
        font-size: 13px;
        left: 0;
        position: absolute;
      }
    }

    .attrachment:before {
      content: "\f1c1";
    }

    .attrachmentVideo:before {
      content: "\f144";
    }

    .duration:before {
      content: "\f017";
    }

    .question:before {
      content: "\f059";
    }
  }
}

/* Flat accordion
-------------------------------------------------------------- */

.flatAccordion {
  .flatToggle {
    padding-left: 58px;
    border: 2px solid #e5e5e5;
    position: relative;
    margin-bottom: 10px;
  }

  .toggleContent {
    font-size: 14px;
    line-height: 25px;

    .faqImg {
      float: left;
      margin-right: 30px;
      margin-top: 7px;
    }

    > div {
      overflow: hidden;

      p {
        margin-bottom: 10px;
      }
    }
  }
}

.flatToggle:before {
  position: absolute;
  left: 22px;
  top: 14px;
  content: "";
  width: 14px;
  border: 2px solid #ffaa30;
  background: #ffaa30;
  height: 14px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}

.flatAccordion .flatToggle {
  h6.toggleTitle {
    position: relative;
    cursor: pointer;
    color: #424242;
    line-height: 46px;
    margin-bottom: 0;

    &:after {
      position: absolute;
      left: -34px;
      top: 20px;
      content: "";
      width: 10px;
      height: 2px;
      background-color: #fff;
    }

    &:before {
      position: absolute;
      left: -30px;
      top: 16px;
      content: "";
      width: 2px;
      height: 10px;
      background-color: #fff;
    }

    &.active:before {
      display: none;
    }
  }

  .toggleContent {
    display: none;
    margin-left: -5px;
    padding: 10px 22px 20px 0px;
  }
}

/* form
-------------------------------------------------------------- */
/* Border-white */

.borderWhite {
  select,
  textarea {
    border-color: #eeeeee;
    background-color: transparent;
  }

  input {
    &[type="text"],
    &[type="password"],
    &[type="datetime"],
    &[type="datetime-local"],
    &[type="date"],
    &[type="month"],
    &[type="time"],
    &[type="week"],
    &[type="number"],
    &[type="email"],
    &[type="url"],
    &[type="search"],
    &[type="tel"],
    &[type="color"] {
      border-color: #eeeeee;
      background-color: transparent;
    }
  }

  textarea {
    &:-moz-placeholder,
    &::-moz-placeholder {
      color: #ffffff;
      opacity: 1;
    }
  }

  input {
    &:-moz-placeholder,
    &::-moz-placeholder {
      color: #ffffff;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: #ffffff;
    }
  }

  textarea::-webkit-input-placeholder,
  input::-webkit-input-placeholder {
    color: #ffffff;
    opacity: 1;
  }
}

/* Border large */

.borderLarge {
  select,
  textarea {
    border-width: 2px;
  }

  input {
    &[type="text"],
    &[type="password"],
    &[type="datetime"],
    &[type="datetime-local"],
    &[type="date"],
    &[type="month"],
    &[type="time"],
    &[type="week"],
    &[type="number"],
    &[type="email"],
    &[type="url"],
    &[type="search"],
    &[type="tel"],
    &[type="color"] {
      border-width: 2px;
    }
  }
}

/* Border radius */

.borderRadius {
  select {
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -o-border-radius: 50px;
    border-radius: 50px;
  }

  input {
    &[type="text"],
    &[type="password"],
    &[type="datetime"],
    &[type="datetime-local"],
    &[type="date"],
    &[type="month"],
    &[type="time"],
    &[type="week"],
    &[type="number"],
    &[type="email"],
    &[type="url"],
    &[type="search"],
    &[type="tel"],
    &[type="color"] {
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      -o-border-radius: 50px;
      border-radius: 50px;
    }
  }
}

/* field-large */

.fieldLarge input {
  &[type="text"],
  &[type="password"],
  &[type="datetime"],
  &[type="datetime-local"],
  &[type="date"],
  &[type="month"],
  &[type="time"],
  &[type="week"],
  &[type="number"],
  &[type="email"],
  &[type="url"],
  &[type="search"],
  &[type="tel"],
  &[type="color"] {
    height: 60px;
  }
}

/* text-large */

.textLarge {
  select,
  textarea {
    text-transform: uppercase;
    font-weight: 700;
  }

  input {
    &[type="text"],
    &[type="password"],
    &[type="datetime"],
    &[type="datetime-local"],
    &[type="date"],
    &[type="month"],
    &[type="time"],
    &[type="week"],
    &[type="number"],
    &[type="email"],
    &[type="url"],
    &[type="search"],
    &[type="tel"],
    &[type="color"] {
      text-transform: uppercase;
      font-weight: 700;
    }
  }

  select,
  textarea {
    text-align: center;
  }

  input {
    &[type="text"],
    &[type="password"],
    &[type="datetime"],
    &[type="datetime-local"],
    &[type="date"],
    &[type="month"],
    &[type="time"],
    &[type="week"],
    &[type="number"],
    &[type="email"],
    &[type="url"],
    &[type="search"],
    &[type="tel"],
    &[type="color"] {
      text-align: center;
    }
  }
}

/* text-center */

/* flat-counter
-------------------------------------------------------------- */
/* flat-counter
-------------------------------------------------------------- */

.flatCounter {
  .numbCount {
    font-family: "Montserrat", sans-serif;
    font-size: 65px;
    line-height: 65px;
    color: #ffaa30;
    margin-bottom: 11px;

    &.percent {
      position: relative;

      &:after {
        position: absolute;
        right: 127px;
        top: -10px;
        content: "%";
        font-size: 40px;
        color: #ffaa30;
      }
    }
  }

  .nameCount {
    font-family: "montserrat", sans-serif;
    font-size: 18px;
    color: #fff;
    margin-bottom: 18px;
  }

  .descCount {
    color: #fff;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
    filter: alpha(opacity = 30);
    opacity: 0.3;
    line-height: 22px;
  }
}

/* flat-Contact
-------------------------------------------------------------- */

.flatContact {
  .mail {
    font-size: 30px;
    line-height: 30px;
    color: #fff;
  }

  .phcol {
    font-size: 60px;
    line-height: 60px;
    color: #f96868;
    font-weight: 900;
    margin-bottom: 24px;
  }

  .address {
    font-size: 24px;
    line-height: 24px;
    color: #4d4d4d;
  }
}

/* Flat course 
-------------------------------------------------------------- */

.flatCourse {
  border-radius: 3px;

  img {
    width: 100%;
  }

  .courseMeta {
    &.review li {
      margin-right: 14px;
    }

    li {
      display: inline-block;
      margin-right: 45px;
      color: #999;
      position: relative;
      font-size: 14px;
    }

    &.desc li {
      &:after {
        width: 1px;
        height: 40px;
        background-color: #e5e5e5;
        position: absolute;
        content: "";
        right: -30px;
        top: 0;
      }

      &:last-child {
        margin-right: 0;

        &:after {
          content: none;
        }
      }
    }

    &.review {
      margin-bottom: 17px;
    }
  }

  .course.meta.review li {
    padding-right: 11px;
    padding-left: 0px;
  }

  .courseMeta {
    &.review li .thumb img {
      width: 40px;
      border-radius: 40px;
    }

    &.desc li h6 {
      margin-bottom: 6px;
    }
  }

  .courseContent {
    position: relative;
    padding: 40px 29px 24px;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    border-bottom: 3px solid #e5e5e5;
    box-shadow: 0px 2px 0px 0px rgba(229, 229, 229, 1);
    line-height: 22px;

    p:last-of-type {
      margin-bottom: 28px;
    }

    h4 {
      margin-bottom: 9px;
      a {
        color: #428bdc;
      }
    }

    .price {
      line-height: 32px;
      color: #fff;
      padding: 0 15px;
      position: absolute;
      right: -7px;
      top: 0px;
      background: #428bdc;
      font-size: 17px;

      &:after,
      &:before {
        content: "";
        width: 0;
        height: 0;
        top: 0;
        right: 0;
        border-top: 7px solid transparent !important;
        border-left: 7px solid #428bdc;
        position: absolute;
        transform: translateY(-100%);
        -moz-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        -webkit-transform: translateY(-100%);
        -o-transform: translateY(-100%);
      }

      &:after {
        border-color: #000;
        opacity: 0.2;
      }
    }
  }
}

.reviewStars i {
  color: #ffaa30;
  font-size: 14px;
}

/* flat-Respond-form
-------------------------------------------------------------- */

.flatRespondForm {
  /*margin-top: 50px;*/

  &.short .input-wrap {
    width: 49.5%;
    margin-right: 1%;
    float: left;
  }

  .input-wrap {
    /*margin-bottom: 10px;*/
    width: 100%;
  }

  div {
    margin-bottom: 20px;
  }

  .input-wrap.last {
    margin-right: 0;
  }

  textarea {
    &:-moz-placeholder,
    &::-moz-placeholder {
      /*color: #4d4d4d;*/
      opacity: 1;
    }
  }

  input {
    &:-moz-placeholder,
    &::-moz-placeholder {
      /*color: #4d4d4d;*/
      opacity: 1;
    }

    &:-ms-input-placeholder {
      /*color: #4d4d4d;*/
    }
  }

  textarea::-webkit-input-placeholder,
  input::-webkit-input-placeholder {
    /*color: #4d4d4d;*/
    opacity: 1;
  }

  .textareaWrap textarea {
    resize: ncol;
  }

  .inputWrap input,
  .textareaWrap textarea {
    background: rgba(0, 0, 0, 0.1);

    /*color: #4d4d4d;*/
    width: 100%;

    /*box-shadow: 0 -1px 0 #e4e4e4 inset;*/
    /*border: 1px solid #e4e4e4;*/
    border: ncol;
    border-bottom: 3px solid rgba(0, 0, 0, 0.1);
    padding: 10px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
    -ms-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
    -o-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
  }

  input:focus {
    border: 1px solid #f96868;
    outline: 0;
  }

  textarea {
    &:focus {
      border: 1px solid #f96868;
      outline: 0;
    }

    height: auto;
  }

  .submitWrap {
    /*margin-top: 40px;*/
  }

  .flatButton {
    /* padding: 20px 36px;*/
    background-color: #f1c40f;
  }

  label.error {
    color: #f96868;
    font-style: italic;
    font-size: 13px;
    line-height: 30px;
  }

  input.error,
  textarea.error {
    border: 1px solid #f96868;
  }

  .loading {
    display: inline-block;
    width: 16px;
    height: 16px;
    // background: url(../images/ajax-loader.gif) no-repeat center center;
  }
}

/* Flat Porfolio 
-----------------------------------------------------------------*/

.portfolioFilter {
  margin-bottom: 33px;
  text-align: center;

  li {
    display: inline-block;
  }
}

.porfolioFilter li a {
  display: inline-block;
}

.portfolioFilter li {
  a {
    font-size: 16px;
    line-height: 16px;
    color: #b1b1b1;
    padding-right: 32px;
    position: relative;
    font-family: Montserrat, sans-serif;
  }

  &.active a {
    color: #333;
  }

  &:last-child a {
    padding-right: 0;
  }

  &:not(:last-child) a:after {
    content: "|";
    position: absolute;
    right: 12px;
    color: #b1b1b1;
  }
}

/* Top 
-----------------------------------------------------------------*/

.top {
  border-bottom: 1px solid #e5e5e5;
}

.textInformation p {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 23px;
  padding-top: 14px;
}

.flatInformation > li {
  &.email {
    margin-left: 43px;
  }

  display: inline-block;
  position: relative;

  > a {
    display: inline-block;
    padding: 14px 0 10px 14px;
    color: #676767;
    font-size: 14px;

    > i {
      font-size: 14px;
    }
  }
}

.flatSocials {
  > li {
    &.facebook {
      padding-left: 20px;

      > a {
        color: #4b6ea9;
      }
    }

    display: inline-block;

    > a {
      display: inline-block;
      padding: 14px 0 10px 14px;
      color: #676767;
      font-size: 14px;
    }
  }

  display: inline-block;
}

.flatInformation > li.facebook > a {
  color: #4b6ea9;
}

.flatSocials > li.instagram > a,
.flatInformation > li.instagram > a {
  color: #65bbf2;
}

.flatSocials > li.linkedin > a,
.flatInformation > li.linkedin > a {
  color: #008bc2;
}

.flatSocials > li.youtube > a,
.flatInformation > li.youtube > a {
  color: #f43522;
}

.flatSocials > li > a:hover,
.flatInformation > li > a:hover {
  color: #feb800;
}

.textInformation,
.flatInformation {
  display: inline-block;
}

/* Flat spacer 
--------------------------------------------------------------------------- */

.flatSpacer {
  height: 22px;

  &.d74px {
    height: 74px;
  }
}

.h8px {
  height: 8px;
}

.marginbt12px {
  margin-bottom: 12px;
}

/* Flat course images
--------------------------------------------------------------------------- */

.flatCourseImages {
  margin-right: -6px;
  padding-top: 6px;

  li {
    display: inline-block;
    margin-right: 6px;
    position: relative;
    overflow: hidden;

    &:after {
      background: #fff;
      content: "";
      height: 300px;
      left: -150px;
      opacity: 0.2;
      position: absolute;
      top: -50px;
      -webkit-transform: rotate(35deg);
      transform: rotate(35deg);
      -webkit-transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
      width: 50px;
      z-index: 10;
    }

    &:hover:after {
      left: 130%;
      -webkit-transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
    }
  }
}

/* Flat teacher team
--------------------------------------------------------------------------- */

.flatTeacher {
  .content {
    padding: 30px;
    position: relative;
    padding: 26px 29px 18px;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    border-bottom: 3px solid #e5e5e5;
    box-shadow: 0px 2px 0px 0px rgba(229, 229, 229, 1);
    line-height: 22px;

    h4 {
      margin-bottom: 17px;
    }
  }

  .flatSocials {
    margin-bottom: 12px;

    > li {
      &:first-child {
        padding-left: 3px;

        a {
          padding-left: 3px;
        }
      }

      padding: 0 1px;

      a {
        line-height: 30px;
        height: 30px;
        width: 30px;
        border: 1px solid #4b6ea9;
        border-radius: 30px;
        padding: 0;
        text-align: center;
      }

      &.instagram a {
        border-color: #65bbf2;
      }

      &.linkedin a {
        border-color: #008bc2;
      }

      &.youtube a {
        border-color: #f43522;
      }

      &:hover a {
        border-color: #ffaa30;
      }
    }
  }

  .flatInformation > li {
    &:before {
      top: 4px;
      left: 0;
    }

    &.email {
      margin-left: 0;
    }

    a {
      font-size: 13px;
      line-height: 31px;
      padding: 0 21px;
    }

    &.position:before {
      content: "\f006";
    }

    &.skype:before {
      content: "\f17e";
    }
  }
}

/* Flat carousel button right
--------------------------------------------------------------------------- */

.owlControls {
  text-align: center;
}

.buttonRight.owlTheme .owlControls .owlNav div {
  &:hover {
    background-color: #ffaa30;
    border-color: #ffaa30;

    &:before {
      color: #fff;
    }
  }

  &.owlNext {
    top: -70px;
    right: 0;
  }

  &.owlPrev {
    top: -70px;
    right: 47px;
    left: auto;
  }
}

/* flat hover zoom */

.flatHoverZoom {
  .entryImage img {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.6s;
    transition: 0.6s;
  }

  &:hover .entryImage img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }

  .entryImage {
    overflow: hidden;
  }
}

.widgetMailchimb {
  form {
    position: relative;
  }

  #subscribeEmail {
    width: 100%;
  }

  .subscribeButton {
    right: 0;
    top: 0;
    position: absolute;
  }
}

/* Flat course price 
--------------------------------------------------------------------------- */

.courseWidgetPrice {
  padding: 38px 23px;
  border-radius: 5px;
  border: 1px solid #e3e3e3;

  .courseTitle {
    padding-bottom: 17px;
    position: relative;
    margin-bottom: 17px;

    &:after {
      content: "";
      height: 1px;
      background: #000;
      width: 35px;
      bottom: 0;
      position: absolute;
      left: 0;
    }
  }

  ul {
    margin-bottom: 17px;
  }

  li {
    font-size: 13px;
    line-height: 49px;
    border-bottom: 1px solid #e3e3e3;

    i {
      padding-right: 9px;
      font-size: 14px;
      padding-left: 2px;
    }

    span:last-child {
      float: right;
    }
  }

  .btCourse span {
    font-weight: 700;
    font-size: 20px;
  }

  .flatButton {
    line-height: 58px;
    padding: 0 41px 0 38px;
    letter-spacing: 0.4px;
  }
}

.mainContent {
  padding: 100px 0;
  &.courseSingle {
    .blogTitleSingle {
      h1 {
        margin-left: -3px;
        letter-spacing: -0.2px;
        margin-bottom: 37px;
        text-transform: none;
      }

      margin-bottom: 50px;
    }

    img {
      width: 100%;
    }

    svg {
      margin-right: 10px;
    }
  }

  &.blogPosts {
    .blogTitleSingle {
      h1 {
        font-family: "Montserrat", sans-serif;
        font-size: 35px;
        color: #333;
        font-weight: 700;
        position: relative;
      }
    }
  }
}

.post .datePost {
  float: left;
  border: 2px solid #58a1ce;
  padding: 12px 10px 9px;
  padding-bottom: 6px;
  text-align: center;

  span {
    display: block;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    line-height: 22px;
    color: #58a1ce;
    text-transform: uppercase;
  }

  span.numb {
    margin-bottom: 5px;
    font-size: 30px;
  }
}

.postLastestNew {
  .post {
    margin-bottom: 20px;
  }

  .titlePost {
    padding: 0 15px 0 0;
    margin-bottom: 17px;
    line-height: 22px;

    a {
      font-family: "Montserrat", sans-serif;
      font-size: 18px;
      line-height: 10px;
    }
  }

  .entryImage {
    margin-bottom: 31px;
    overflow: hidden;
  }

  .contentPost {
    padding: 0 15px 0 70px;
    margin-top: -7px;
    line-height: 22px;

    .entryContent {
      margin-bottom: 30px;
      position: relative;

      &:before {
        bottom: -17px;
        position: absolute;
        left: 0;
        width: 30px;
        height: 2px;
        content: "";
        background-color: #333;
      }
    }
  }

  .entryMeta.style1 {
    p {
      margin-bottom: 0px;
    }

    a {
      color: #428bdc;

      &:hover {
        color: #58a1ce;
      }
    }
  }

  .post .datePost {
    float: left;
    border: 2px solid #58a1ce;
    padding: 12px 10px 9px;
    padding-bottom: 6px;
    text-align: center;

    span {
      display: block;
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      line-height: 22px;
      color: #58a1ce;
      text-transform: uppercase;
    }

    span.numb {
      margin-bottom: 5px;
      font-size: 30px;
    }
  }
}

.justify {
  p {
    text-align: justify;
  }
}

.joinUs {
  .counterContent {
    margin-bottom: 26px;
    text-align: center;
    width: 100%;
  }

  .numbCounter {
    display: inline-block;
    margin: 0 5px;
  }

  .numbCount {
    color: #58a1ce;
    font-size: 60px;
    font-family: "Montserrat", sans-serif;
  }

  .counterContent span {
    font-size: 20px;
    color: #888888;
  }

  p {
    font-size: 18px;
    color: #fff;
    line-height: 30px;
    text-align: center;
    padding: 0 124px;
    letter-spacing: -0.2px;
    margin-bottom: 35px;
  }

  .flatButton {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    padding: 0 30px;
  }

  .overlay.bg222 {
    background-color: rgba(34, 34, 34, 0.9);
    padding: 93px 0 85px;
  }
}

@media only screen and (max-width: 1199px) {
  .btnMenu {
    display: block !important;
    margin-right: 0 !important;
    margin-left: 20px !important;
    margin-top: 39px !important;
  }

  .mainnav {
    display: none !important;
  }

  .showMenu {
    display: block !important;
    margin: 0 auto;
    width: 100%;
    background-color: #222222;
    z-index: 1000;
    ul {
      li {
        width: 100%;
        a {
          text-decoration: none !important;
          height: 50px !important;
          line-height: 50px !important;
          padding: 0 50px !important;
          color: #fff !important;
          font-family: "Poppins", sans-serif !important;
          text-transform: capitalize !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .btnMenu {
    margin-top: 30px !important;
  }

  .flatButtonContainer {
    text-align: center;
  }

  .flatPdr100 {
    padding: 0 15px;
  }

  .flatRow.course .flatPdr100,
  .haveSidebar .postContent article.post .contentPost {
    margin-bottom: 40px !important;
  }

  .flatRow.joinUs p {
    padding: 0 20px !important;
  }

  .flatRow:not(.contactPage) {
    padding: 40px 0 !important;
  }

  .flatRow.joinUs,
  .flatRow.aboutUs {
    padding: 0 !important;
  }

  .titleCourse {
    padding: 0 !important;
  }

  .footer .widget {
    margin-bottom: 30px !important;
  }
}

@media only screen and (max-width: 767px) {
  .top .flatInformation,
  .textInformation,
  .top .flatSocials {
    text-align: center;
    width: 100%;
  }

  .flatInformation > li > a {
    padding: 5px 12px;
  }

  .headerInnerPages {
    p {
      padding-top: 10px;
    }
  }

  .top {
    .rightBar {
      float: none !important;
    }
  }

  .post .datePost {
    margin-right: 10px;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 479px) {
  .flatInformation > li,
  .flatInformation > li.email {
    margin: 0;
  }

  .postLastestNew .post .datePost {
    margin-right: 15px;
  }

  .contentPost {
    padding: 0 !important;
  }

  .slider {
    height: 300px !important;
  }

  .sliderContent {
    .inner {
      padding: 0 3px !important;
    }

    h1 {
      font-size: 16px !important;
    }
  }

  .mainContent {
    &.courseGrid {
      .flatCourse {
        width: 100% !important;
      }

      .flatPostOrdering {
        top: 70px !important;
        padding: 0 10px;
        left: 5px;

        .sortViews {
          float: left !important;
        }
      }

      .postWrap {
        margin-top: 10px;
      }
    }
  }

  .clientCustomRow {
    margin-bottom: 0 !important;
    div {
      margin-bottom: 5px;
    }
  }
}
