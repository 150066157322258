@import "../../styles/shorcodes.module.scss";

.clientCustomRow {
  margin-bottom: 30px;

  .clientCol {
    .imgContainer {
      overflow: hidden;
      display: inline-block;

      img {
        display: block;
        transition: transform 0.4s;
      }

      &:hover img {
        transform: scale(1.1);
        transform-origin: 50% 50%;
      }
    }
  }

  //   img:hover {
  //     transform: scale(1.1);
  //   }
}
