$white: #ffffff;
$black: #000000;
$alabaster: #fcfcfc;
$sunshade: #ffaa30;
$selective-yellow: #feb800;
$carnation: #f96868;
$azure-radiance: #0089ff;
$havelock-blue: #428bdc;
$gallery: #eeeeee;
$quick-silver: #999999;
$eerie-black: #222222;
$coconut: #fdfdfd;
$hoki: #697ea4;

$san-marino: #4b6ea9;
$blue-jeans: #65bbf2;
$lochmara: #008bc2;
$pomegranate: #f43522;
$buttercup: #f1c40f;
$silver-chalice: #b1b1b1;
$danube: #58a1ce;

//Mercury
$mercurye3e: #e3e3e3;
$mercurye5e: #e5e5e5;

//Silver
$silverbcb: #bcbcbc;
$silverc3c: #c3c3c3;
$silverbfb: #bfbfbf;

//Thundora
$tundora4d4: #4d4d4d;
$tundora424: #424242;
$tundora4e4: #4e4e4e;

// Quick Silver
$quick-silver999: #999999;
$quick-silver9c9: #9c9c9c;

// Granite Gray
$granite-gray666: #666666;
$granite-gray676: #676767;

// Mine Shaft
$mine-shaft323: #323232;
$mine-shaft333: #333333;
$mine-shaft393: #393939;
