@import "../../styles/shorcodes.module.scss";

.contentTab {
  .contentInner {
    display: none;

    &.active {
      display: block;
    }

    .flatList {
      .content {
        display: flex;
        svg {
          margin: 0 10px;
        }
      }
    }
  }
}

.imgContainer {
  float: left;
}
