@import "../../styles/shorcodes.module.scss";

.footer {
  background: $eerie-black;
  color: $quick-silver9c9;
  padding: 65px 15px 0;

  .footerWidgets {
    padding-bottom: 31px;
  }

  .widget .recent-courses-news {
    ul.flat-reviews li {
      position: relative;
      float: left;
      display: block;
    }

    li .text {
      overflow: hidden;
      color: $granite-gray666;

      a {
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        line-height: 20px;
        color: $mine-shaft333;

        &:hover {
          color: $danube;
        }
      }
    }
  }

  .footerWidgets {
    .flatContactForm .flatButton {
      background: $havelock-blue;
      border-color: $havelock-blue;

      &:hover {
        background: $danube;
        border-color: $danube;
      }
    }

    .col-md-3:first-child {
      padding-top: 6px;
    }
  }

  .widgetText {
    img {
      border-radius: 4px;
      margin-bottom: 26px;
    }

    li {
      position: relative;
      //   padding-left: 16px;

      svg {
        margin-right: 5px;
      }

      &.email {
        padding-left: 22px;
      }

      font-size: 14px;
      color: $quick-silver9c9;
      line-height: 29px;

      a {
        font-size: 14px;
        color: $quick-silver9c9;
        line-height: 29px;

        &:hover {
          color: $danube;
        }
      }
    }
  }

  .widget .widgetTitle {
    text-transform: none;
    color: $white;
    margin-bottom: 56px;
  }
}

/* WidgetText */

footer .widget.widget_tweets .widgetTitle {
  margin-bottom: 47px;
}

.footer .widget .widgetTitle:before {
  background-color: $tundora4e4;
  bottom: -17px;
}

/* Widget_tweets */

footer .widget_tweets {
  ul li a {
    font-size: 14px;
    line-height: 32px;
    color: $quick-silver9c9;
    font-weight: 600;

    &:hover {
      color: $danube;
    }
  }

  .linkLeft {
    float: left;
  }

  .linkRight {
    float: right;
    min-width: 139px;
  }
}

/* widget_recent-courses */

.recent-courses-news .text {
  a {
    margin-right: 10px;
  }

  margin-bottom: 7px;
}

footer .widget_recent-courses {
  margin-right: -15px;

  ul li {
    .thumb {
      float: left;
      margin-right: 20px;
    }

    .review-rating {
      font-size: 14px;

      .flat-reviews {
        margin-right: 7px;
      }

      ul li {
        display: inline-block;
        margin-right: 3.6px;
        font-size: 14px;

        a {
          color: $danube;
        }
      }

      .flat-reviews {
        float: left;
      }
    }
  }
}

.footer .widget {
  .recent-courses-news li {
    &.star {
      margin-bottom: 0;
    }

    .text a {
      color: $quick-silver9c9;

      &:hover {
        color: $danube;
      }
    }
  }

  &.widget_recent-courses {
    li {
      border-bottom: 1px solid $mine-shaft323;
      padding: 19px 0 8px;

      &:first-child {
        padding-top: 0;
      }
    }

    .review-rating ul li {
      border-bottom: none;
      padding: 0;
    }
  }
}

/* WidgetquickContact */

.widgetQuickContact {
  textarea {
    height: 120px;
  }

  input[type="email"] {
    margin-bottom: 10px;
    background: $eerie-black;
    width: 100%;
  }

  textarea,
  input[type="email"] {
    color: $white;
    border-radius: 4px;
    border: 1px solid $mine-shaft393;
    padding: 15px 19px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  textarea:focus,
  input[type="email"]:focus {
    border: 1px solid $danube;
  }

  button {
    position: absolute;
    bottom: 28px;
    right: 16px;
    height: 30px;
    line-height: 30px;
    width: 50px;
    padding: 0;
    border-radius: 4px 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .flatButton:hover {
    background-color: $danube;
    border-color: $danube;
  }
}

.widget .widgetTitle {
  position: relative;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  color: $mine-shaft333;
  font-size: 18px;
  line-height: 19px;
  text-transform: uppercase;
  margin-bottom: 54px;
  padding-top: 7px;
}

.widget .widgetTitle:before {
  position: absolute;
  left: 0;
  bottom: -18px;
  content: "";
  width: 35px;
  height: 1px;
  background-color: $mine-shaft333;
}
