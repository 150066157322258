@import "../../styles/shorcodes.module.scss";

.titleCourse {
  letter-spacing: -0.4px;
  padding-right: 20px;
}

.headerInnerPages p {
  margin-bottom: 0;
}

.flatLh25 {
  line-height: 25px;
}

.flatPdr100 {
  padding-right: 100px;
}

.flatRow {
  &.course {
    border-bottom: 1px solid #efefef;
  }

  &.popularCourse {
    padding: 93px 0 98px;

    .featuredPost {
      cursor: none;
    }
  }

  &.joinUs,
  &.aboutUs {
    padding: 0;
  }

  &.aboutUs .overlay.bg222 {
    background-color: rgba(34, 34, 34, 0.9);
    padding: 93px 0 85px;
  }

  &.lastestNew {
    padding: 92px 0 72px;
    border-bottom: 1px solid #efefef;
  }
}

.flatRow.partners {
  padding: 34px 0 36px;
  background: #fcfcfc;
}

.partnersSlider li {
  text-align: center;
}

.flatRow.testimonial {
  //   background: url("../images/index/bg-testimonial.jpg") no-repeat;
  padding: 93px 0 96px;

  .testimonialSlider {
    border-radius: 4px;
    background: #e7e7e8;
    opacity: 0.9;
    filter: Alpha(opacity = 90);

    /* IE8 and earlier */
    width: 55.55555556%;
    float: right;
    padding: 70px 45px 53px;
    text-align: center;
    box-shadow: 0px 26px 16px 0px rgba(0, 0, 0, 0.27);
  }

  .testimonialContent {
    font-size: 16px;
    line-height: 27px;
    color: #333;
    margin-bottom: 37px;
    padding-top: 89px;
    position: relative;
    letter-spacing: 0px;
    font-weight: 500;

    blockquote {
      cursor: e-resize;
    }

    &:before {
      content: "";
      top: 0;
      //   background: url(../images/index/testimonial-icon.png) no-repeat;
      position: absolute;
      width: 66px;
      height: 54px;
      text-align: center;
      transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -o-transform: translateX(-50%);
    }
  }

  .testimonialAuthor {
    margin-bottom: 27px;

    .authorName {
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
      font-family: "Montserrat", sans-serif;
    }

    .authorInfo {
      font-size: 14px;
      line-height: 27px;
      color: #999;
      font-family: "Montserrat", sans-serif;
    }
  }
}

.flatRow.newsLetter {
  padding-bottom: 0;
}

.haveQuestion {
  text-align: center;
  padding: 0 230px;
  margin-bottom: 45px;

  h2 {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 24px;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
  }

  .readMore {
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    padding-left: 30px;
  }
}

.newsLetterForm {
  min-height: 646px;
  //   background: url(../images/index/bg-news-letter.jpg);
  position: relative;
  background-position: center;

  .widgetMailchimb {
    position: absolute;
    bottom: 38px;
    left: 50%;
    transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    text-align: center;

    h1 {
      margin-bottom: 20px;
    }

    p {
      font-size: 14px;
      line-height: 22px;
      color: #666;
      margin-bottom: 30px;
    }

    input[type="email"] {
      width: 75%;
    }

    button {
      padding: 0 21px 0 29px;
    }
  }
}
