@import "../../styles/shorcodes.module.scss";

.containerPagination {
  text-align: center;
  width: 100%;

  .flatPagination {
    li {
      display: inline-block;
      margin-left: 1px;
      border: 1px solid #dfdfdf;
      color: #000;
      border-radius: 4px;

      &.active {
        background-color: #58a1ce;
        border-radius: 4px;
        display: inline-block;
        line-height: 23px;
        font-size: 14px;
        a {
          color: #fff;
        }
      }

      &:hover {
        background-color: #58a1ce;
        border-radius: 4px;
        display: inline-block;
        line-height: 23px;
        font-size: 14px;
        a {
          color: #fff;
        }
      }

      a {
        display: inline-block;
        line-height: 23px;
        font-size: 14px;
        padding: 10px 18px;
        font-family: "Montserrat", sans-serif;
        color: #333;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}
