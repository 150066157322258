html {
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  line-height: 23px;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
caption,
canvas,
center,
cite,
code,
dd,
del,
details,
dfn,
dialog,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
form,
footer,
header,
hgroup,
h1,
h2,
h3,
h4,
h5,
h6,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
tt,
table,
tbody,
textarea,
tfoot,
thead,
time,
tr,
th,
td,
u,
ul,
var,
video {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  font-style: inherit;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  quotes: none;
}

figure {
  margin: 0;
}

:focus {
  outline: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}

legend {
  white-space: normal;
}

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  max-width: 100%;
  vertical-align: baseline;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

button,
input {
  line-height: normal;
}

input,
textarea {
  background-image: -webkit-linear-gradient(
    hsla(0, 0%, 100%, 0),
    hsla(0, 0%, 100%, 0)
  ); /* Removing the inner shadow, rounded corners on iOS inputs */
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  line-height: 1;
  cursor: pointer; /* Improves usability and consistency of cursor style between image-type 'input' and others */
  -webkit-appearance: button; /* Corrects inability to style clickable 'input' types in iOS */
  border: none;
}

input[type="checkbox"],
input[type="radio"] {
  padding: 0; /* Addresses excess padding in IE8/9 */
}

input[type="search"] {
  -webkit-appearance: textfield; /* Addresses appearance set to searchfield in S5, Chrome */
}

input[type="search"]::-webkit-search-decoration {
  /* Corrects inner padding displayed oddly in S5, Chrome on OSX */
  -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  /* Corrects inner padding and border displayed oddly in FF3/4 www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/ */
  border: 0;
  padding: 0;
}

th {
  letter-spacing: 2px;
  text-align: center;
  background-color: #ccc;
  border-radius: 10px;
  padding: 10px;
}

td {
  letter-spacing: 1px;
  border-collapse: collapse;
  border: 2px solid grey;
}

tbody td {
  text-align: center;
}

tfoot th {
  text-align: right;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
