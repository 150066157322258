@import "../../../styles/colors.scss";
@import "../../../styles/shorcodes.module.scss";

.headerInnerPages {
  p {
    margin-bottom: 0;
  }

  .top {
    .rightBar {
      float: right;
      .flatInformation {
        margin-right: 21px;

        > li {
          > a {
            > svg {
              font-size: 14px;
              margin-right: 5px;
              color: $granite-gray676;
            }
            &:hover {
              color: $selective-yellow;
            }
          }
        }
        > li.instagram {
          > a {
            color: $blue-jeans;
          }
        }
        > li.linkedin {
          > a {
            color: $lochmara;
          }
        }
        > li.youtube {
          > a {
            color: $pomegranate;
          }
        }
      }

      .flatSocials {
        > li.facebook {
          padding-left: 0px;
          > a {
            &:hover {
              color: $san-marino;
            }
          }
        }
        > li {
          > a {
            display: inline-block;
            padding: 14px 0 10px 14px;
            color: $granite-gray676;
            font-size: 14px;
            &:hover {
              color: $selective-yellow;
            }
          }
        }
        > li.instagram {
          > a {
            &:hover {
              color: $blue-jeans;
            }
          }
        }
        > li.linkedin {
          > a {
            &:hover {
              color: $lochmara;
            }
          }
        }
        > li.youtube {
          > a {
            &:hover {
              color: $pomegranate;
            }
          }
        }
      }
    }
  }
}
