* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  &:before,
  &:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

body {
  font: 13px/23px "Poppins", sans-serif;
  font-weight: 400;
  background-color: #fff;
  color: #666666;
}

a {
  color: #ccc;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  &:hover,
  &.active,
  &:focus {
    color: #58a1ce;
    text-decoration: none;
    outline: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
}

ul,
ol {
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
}

.imgLeft {
  margin: -10px 50px 0 0 !important;
  float: left;
}

.imgRight {
  margin: 0 0 0 50px !important;
  float: right;
}

b,
strong {
  font-weight: 900;
}

.entryPage p {
  margin-bottom: 25px;
}

h1 {
  font-size: 35px;
  font-weight: 700;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  color: #333333;
  margin-bottom: 26px;
}

p {
  margin-bottom: 20px;
}

button {
  border: none;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  color: #ffffff;
  background-image: none;
  height: 50px;
  background-color: #58a1ce;
  line-height: 50px;
  padding: 0 24px 0 32px;
  cursor: pointer;

  /*letter-spacing: 1px;*/
  white-space: nowrap;
  border: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  display: inline-block;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

input {
  &[type="button"],
  &[type="reset"],
  &[type="submit"] {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    color: #ffffff;
    background-image: none;
    height: 50px;
    background-color: #58a1ce;
    line-height: 50px;
    padding: 0 24px 0 32px;
    cursor: pointer;

    /*letter-spacing: 1px;*/
    white-space: nowrap;
    border: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    display: inline-block;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
}

button:hover {
  background-color: #222;
  border-color: #222;
}

input {
  &[type="button"]:hover,
  &[type="reset"]:hover,
  &[type="submit"]:hover {
    background-color: #222;
    border-color: #222;
  }
}

select,
textarea {
  border: 1px solid #e5e5e5;
  height: 50px;

  /*padding: 20px;  	*/
  font-size: 14px;
  line-height: 19px;
  color: #333333;
  max-width: 100%;
  margin-bottom: 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 100%;
  padding: 12px 35px;
  padding-top: 12px;
  padding-right: 35px;
  padding-bottom: 12px;
  padding-left: 20px;
}

input {
  &[type="text"],
  &[type="password"],
  &[type="datetime"],
  &[type="datetime-local"],
  &[type="date"],
  &[type="month"],
  &[type="time"],
  &[type="week"],
  &[type="number"],
  &[type="email"],
  &[type="url"],
  &[type="search"],
  &[type="tel"],
  &[type="color"] {
    border: 1px solid #e5e5e5;
    height: 50px;

    /*padding: 20px;  	*/
    font-size: 14px;
    line-height: 19px;
    color: #333333;
    max-width: 100%;
    margin-bottom: 20px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    width: 100%;
    padding: 12px 35px;
    padding-top: 12px;
    padding-right: 35px;
    padding-bottom: 12px;
    padding-left: 20px;
  }
}

textarea:focus {
  border: 1px solid #58a1ce;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

input {
  &[type="text"]:focus,
  &[type="password"]:focus,
  &[type="datetime"]:focus,
  &[type="datetime-local"]:focus,
  &[type="date"]:focus,
  &[type="month"]:focus,
  &[type="time"]:focus,
  &[type="week"]:focus,
  &[type="number"]:focus,
  &[type="email"]:focus,
  &[type="url"]:focus,
  &[type="search"]:focus,
  &[type="tel"]:focus,
  &[type="color"]:focus {
    border: 1px solid #58a1ce;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}

textarea {
  width: 100%;
  height: 200px;
  padding: 14px 20px;
}

input[type="checkbox"] {
  display: inline;
}

textarea {
  &:-moz-placeholder,
  &::-moz-placeholder {
    font-size: 14px;
    line-height: 19px;
    color: #b1b1b1;
    opacity: 1;
  }
}

input {
  &:-moz-placeholder,
  &::-moz-placeholder {
    font-size: 14px;
    line-height: 19px;
    color: #b1b1b1;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    font-size: 14px;
    line-height: 19px;
    color: #b1b1b1;
  }
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 19px;
  color: #b1b1b1;
  opacity: 1;
}

@-webkit-keyframes pop-scale {
  0% {
    -webkit-transform: scale(0.7);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes pop-scale {
  0% {
    -moz-transform: scale(0.7);
  }

  100% {
    -moz-transform: scale(1);
  }
}

@keyframes pop-scale {
  0% {
    transform: scale(0.7);
  }

  100% {
    transform: scale(1);
  }
}

/* bootstrap resetting elements */

.btn {
  background-image: none;
}

textarea {
  -webkit-appearance: none;
  text-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  color: #333333;
}

input {
  &[type="text"],
  &[type="submit"],
  &[type="password"],
  &[type="datetime"],
  &[type="datetime-local"],
  &[type="date"],
  &[type="month"],
  &[type="time"],
  &[type="week"],
  &[type="number"],
  &[type="email"],
  &[type="url"],
  &[type="search"],
  &[type="tel"],
  &[type="color"] {
    -webkit-appearance: none;
    text-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    color: #333333;
  }
}

.uneditableInput,
.dropdownMenu {
  -webkit-appearance: none;
  text-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  color: #333333;
}

.navbar .nav > .active > a {
  -webkit-appearance: none;
  text-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  color: #333333;

  &:hover,
  &:focus {
    -webkit-appearance: none;
    text-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    color: #333333;
  }
}

.bold {
  font-weight: 700;
}

.clOrange {
  color: #58a1ce;
}

.container {
  padding-left: 0;
  padding-right: 0;
}

.center {
  text-align: center;
}

.blogSingle .entry p {
  margin-bottom: 30px;
}

label.modernSelect {
  position: relative;
  margin-left: 3px;

  &:after {
    content: "\f078";
    font-family: FontAwesome;
    position: absolute;
    top: 0;
    right: 15px;
    font-size: 11px;
    line-height: 40px;
    color: #b1b1b1;
  }
}

.sortViews {
  float: right;

  .listGrid {
    float: right;
  }
}

select.orderby {
  color: #b1b1b1;
  border-color: #ccc;
  border-radius: 1px;
  display: inline-block;
  padding: 0 89px 0 15px;
  margin: 0;
  height: 40px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

label.modernSelect:hover {
  select {
    color: #58a1ce;
    border-color: #58a1ce;
  }

  &:after {
    color: #58a1ce;
  }
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;

  &:hover {
    text-decoration: none;
    color: #25d366;
    background-color: #fff;
  }
}

.myFloat {
  margin-top: 16px;
}

.sortViews a {
  font-size: 14px;
  line-height: 38px;
  border-radius: 1px;
  border: 1px solid #ccc;
  width: 40px;
  display: inline-block;
  text-align: center;
  margin-left: 3px;

  &.courseGridView {
    margin-left: 24px;
  }

  &.active,
  &:hover {
    border-color: #58a1ce;
  }
}

.flatContactUs a {
  color: red;

  &:hover {
    color: #333;
  }
}

.goTop {
  position: fixed !important;
  right: -35px;
  bottom: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: #428bdc;
  line-height: 40px;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity = 0);
  opacity: 0;

  i {
    color: #fff;
  }

  &.show {
    right: 15px;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity = 100);
    opacity: 1;
    visibility: visible;
    z-index: 9999;

    &:hover {
      background: #58a1ce;
    }
  }
}
