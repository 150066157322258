@import "../../styles/shorcodes.module.scss";

.customTable {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  display: table;
  padding: 0;
  text-align: center;

  .customRow {
    display: table-row;
    background: #f6f6f6;

    &:nth-of-type(odd) {
      background: #e9e9e9;
    }

    &.customHeader {
      font-weight: 900;
      color: #ffffff;
      background: #ea6153;
    }

    &.blue {
      background: #2980b9;
    }

    .customCell {
      padding: 6px 12px;
      display: table-cell;
      vertical-align: middle;
    }
  }
}
